<template>
  <div class="cargoController-list">
    <app-list :opt="opt" @get="onGet" ref="myList"></app-list>
  </div>
</template>
<script>
  import cabinetUtil from "@utils/cabinetUtil.js"
  let validMobile = (rule, value, callback) => {
    if (value == "" || value == undefined) {
      callback();
    } else {
      let reg = /^1[3456789]\d{9}$/;
      if (!reg.test(value)) {
        callback(new Error("货管员手机号格式不对"));
      } else {
        callback();
      }
    }
  };
  let validName = (rule, value, callback) => {
    if (value == "" || value == undefined) {
      callback();
    } else {
      //姓名支持输入中英文字母，汉字，1~16个字符
      let reg = /^[a-zA-Z\u4e00-\u9fa5]{1,16}$/;
      if (!reg.test(value)) {
        callback(new Error("货管员姓名格式不对"));
      } else {
        callback();
      }
    }
  };
  export default {
    data() {
      let _this = this;
      return {
        dialogVisible: false,
        opt: {
          title: "货管员列表",
          search: [
            {
              label: "货管员类型",
              key: "type",
              type: "select",
              opt: {
                list: [{
                    label: "货管员",
                    value: "0",
                },
                {
                    label: "小富",
                    value: "1"
                },{
                    label: "平台货管员",
                    value: "2"
                }]
              },
            },
            {
              key: "realName",
              label: "货管员姓名",
              maxlength: 16,
              rules: [{
                validator: validName,
                trigger: ['blur']
              }]
            },
            {
              key: "phone",
              label: "货管员手机号",
              rules: [{
                validator: validMobile,
                trigger: ['blur']
              }]
            },
            {
              key: "merchantId",
              label: "商家名称",
              type: 'remoteSearchById',
              opt: {
                list: []
              }
            },
          ],
          columns: [{
              label: "货管员姓名",
              key: "realName",
              on: row => {
                _this.$router.push({
                  path: "/main/cargoController/detail/show",
                  query: {
                    id: row.id,
                    type: 'show',
                  }
                })
              }
            },
            {
              label: "货管员手机号",
              key: "phone"
            },
            {
              label: "商家名称",
              key: "merchantName"
            },
            {
              label: "货管员账号",
              key: "userName"
            },
            {
              label: "货管员类型",
              key: "userType"
            },
            {
              label: "添加人",
              key: "createdBy"
            },
            {
              label: "添加时间",
              key: "createdTime"
            },
            {
              label: "状态",
              key: "stateStr" //1启用  2禁用
            },
          ],
          buttons: [{
              type: 0,
              on() {
                _this.$router.push({
                  path: "/main/cargoController/detail/add",
                  query: {
                    type: 'add'
                  }
                })

              }
            },
            {
              type: 1,
              on(data) {
                _this.$router.push({
                  path: "/main/cargoController/detail/edit",
                  query: {
                    id: data.id,
                    type: 'edit'
                  }
                })
              }
            },
            {
              type: 2,
              on(data) {
                if (data.ids.length > 1) {
                  return _this.$message({
                    showClose: true,
                    message: "不支持多个删除,只支持单个删除!",
                    type: "warning"
                  });
                }
                const h = _this.$createElement;
                _this
                  .$msgbox({
                    title: "删除提示",
                    message: h("p", null, [
                      h(
                        "span", {
                          style: "color: #333;font-size: 16px;margin-left:98px"
                        },
                        "确定要删除货管员吗？"
                      )
                    ]),
                    showCancelButton: true,
                    confirmButtonText: "确定",
                    cancelButtonText: "取消"
                  })
                  .then(action => {
                    _this
                      .get("mall-service/counterUser/delete/" + data.ids[0])
                      .then(function () {
                        _this.$message({
                          showClose: true,
                          message: "删除货管员成功！",
                          type: "success"
                        });
                        data.refresh();
                      });
                  });
              }
            }
          ]
        },
      };
    },
    created() {
      console.log("cargoController-list created!!");
    },
    activated() {
      let businessFlag = this.getLoginType();
      if (businessFlag) {
        this.opt.columns.forEach((item, index) => {
          if (item.key == 'merchantName') {
            this.opt.columns.splice(index, 1);
          }
        })
        this.opt.search.forEach((item, index) => {
          if (item.key == 'merchantId') {
            this.opt.search.splice(index, 1);
          }
        })
        this.opt.search.forEach((item, index) => {
          if (item.key == 'type') {
            item.opt.list= [{
                label: "货管员",
                value: "0",
            },
            {
                label: "小富",
                value: "1"
            }]
          }
        })
      } else {
        this.opt.search.forEach((item, index) => {
          if (item.key == 'merchantId') {
            cabinetUtil.getMerchantList().then(res => {
              item.opt.list = res;
            })
          }
        })
      }

    },

    methods: {
      onGet(opt) {
        let dto = {
          pageNum: opt.skip,
          pageSize: opt.limit,
          realName: opt.searchForm.realName ? opt.searchForm.realName : null,
          phone: opt.searchForm.phone ? opt.searchForm.phone : null,
          merchantId: opt.searchForm.merchantId ? opt.searchForm.merchantId : '',
          type: opt.searchForm.type
        };
        let businessFlag = this.getLoginType();
        if (!businessFlag) {
          dto.isNeed=1
        }
        this.post("mall-service/counterUser/page/query", dto, {
          isUseResponse: true
        }).then(res => {
          res.data.data = res.data.list;
          res.data.list.forEach((item, index) => {
            item.stateStr = ["启用", "禁用"][item.state - 1];
            item.merchantName = item.merchantNameList.join("、")
            if (item.merchantName.length > 12) {
              let sub = item.merchantName.substring(0, 12)
              item.merchantName = `${sub}...`
            }
            item.userType = ["货管员","小富","平台货管员"][item.type]
          });
          opt.cb(res.data);
        });
      },
    }
  };
</script>
<style lang="scss">

</style>